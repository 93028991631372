import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
          ]}
        >
          <html lang="en" />
          <link
            rel="stylesheet"
            href="https://code.getmdl.io/1.3.0/material.min.css"
          />
          <script defer src="https://code.getmdl.io/1.3.0/material.min.js" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic"
            rel="stylesheet"
            type="text/css"
          />
        </Helmet>

        <div className="demo-layout-transparent mdl-layout mdl-js-layout">
          <Header siteTitle={data.site.siteMetadata.title} />

          <main className="mdl-layout__content">
            <br />
            <br />
            <h2>
              <a
                target="_blank" rel="noopener noreferrer"
                href="http://www.PropertyCasualty360.com/author/gary-jennings"
              >
                Check out our latest article in Property Casualty 360!
              </a>
            </h2>
          </main>
        </div>
        <p
          style={{
            float: 'right',
            marginRight: '15px',
            padding: '5px',
            background: '#00518d',
            color: 'white',
            marginTop: '7px',
            marginBottom:'0px',
            textAlign: 'right',
          }}
        >
          CALL US TODAY{' '}
          <span style={{ fontWeight: 'bold' }}>(678) 520-3739</span>
          <br /> Or{' '}
          <a
            href="mailto:Gary.Jennings@StrategicClaimsDirection.com"
            style={{ color: 'white', fontWeight: 'bold' }}
          >
            Send Us An Email
          </a>
        </p>
        <div style={{ clear: 'both' }} />

        <main role="main">{children}</main>
        <Footer />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
