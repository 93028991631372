import React from 'react'

const Footer = () => (
  <footer className="mdl-mega-footer">
    <div className="mdl-mega-footer__top-section">
      <div className="mdl-mega-footer__drop-down-section">
        <h1 className="mdl-mega-footer__heading">
          Strategic Claims Direction LLC
        </h1>
        <ul className="mdl-mega-footer__link-list">
          <li>9985 Farmbrook Lane</li>
          <li>Johns Creek, GA 30022-5507</li>
        </ul>
      </div>
      <div className="mdl-mega-footer__drop-down-section">
        <h1 className="mdl-mega-footer__heading">Gary Jennings</h1>
        <ul className="mdl-mega-footer__link-list">
          <li>(678) 520-3739</li>
          <li>
            <a href="mailto:Gary.Jennings@StrategicClaimsDirection.com">
              Gary.Jennings@StrategicClaimsDirection.com
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/pub/gary-jennings/1/428/346">
              <img
                src="https://static.licdn.com/scds/common/u/img/webpromo/btn_liprofile_blue_80x15.png"
                width="80"
                height="15"
                border="0"
                alt="View Gary Jennings's profile on LinkedIn"
              />
            </a>
          </li>
        </ul>
      </div>
      <div className="mdl-mega-footer__right-section">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/services">Services</a>
        <a href="/claims-audits">Claims Audits</a>
        <a href="/publications">Publications</a>
        <br />
        <img
          className="aboutImg"
          style={{ float: 'right', maxWidth: '200px' }}
          src="/USA.jpg"
          alt="American Flag"
        />
      </div>
    </div>

    <div className="mdl-mega-footer__bottom-section">
      <div className="mdl-logo">
        <span>
          &copy;2020
          <a target="_blank" rel="noopener noreferrer" href="http://tylerscode.com/about/">
            Tyler Jennings
          </a>
        </span>
      </div>
    </div>
  </footer>
)

export default Footer
