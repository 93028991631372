import React from 'react'
import Layout from '../components/layout'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const About = () => (
  <Layout>
    <div className="mdl-grid">
      <div className="mdl-cell mdl-cell--12-col" id="About">
        <div className="mdl-card mdl-shadow--2dp" style={{ width: 'auto' }}>
          <div className="mdl-card__title mdl-card--expand">
            <div className="mdl-cell mdl-cell--12-col">
              <h2 className="page-header" style={{ padding: '5px' }}>
                About SCD
              </h2>
              <strong>
                <em>
                  WE CAN HELP YOU WITH YOUR CLAIMS MANAGEMENT PROBLEMS AND NEEDS
                  BECAUSE OF OUR DEEP EXPERIENCE WORKING WITH MANY INDUSTRIES.{' '}
                </em>
              </strong>
              <p>
                SCD has worked in many different industries, providing
                independent claims consulting services throughout the United
                States. Some of the industries that we have served include:
              </p>
              <ul>
                <li>Automotive</li>
                <li>Construction</li>
                <li>Energy/Utilities</li>
                <div style={{ float: 'right', width: '60%' }}>
                  <img
                    className="aboutImg"
                    src="/Balancing_effective_and_efficient.png"
                    alt="Balancing effective and efficient"
                  />
                </div>
                <li>Healthcare & Related Services</li>
                <li>Financial Services</li>
                <li>Insurance</li>
                <li>Manufacturing (light & heavy)</li>
                <li>Non-profits</li>
                <li>Personal Services</li>
                <li>Public Entities</li>
                <li>Retail Businesses</li>
                <li>Telecommunications</li>
                <li>Transportation</li>
                <li>Warehousing</li>
              </ul>
              <strong>
                We realize that every company or public entity has its own
                culture, environment, exposures, and expertise. We do not
                provide a "one size fits all" solution and try to get you to fit
                the solution. We get to know you and your specific needs so we
                can recommend the right solutions.{' '}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
export default About
