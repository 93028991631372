import React from 'react'
import './header.css'

const Header = ({ siteTitle, showmenu = false }) => {
  return (
    <div>
      <header className="mdl-layout__header mdl-layout__header--transparent">
        <div className="mdl-layout__header-row">
          <a
            className="mdl-navigation__link"
            href="/"
          >
            <span className="mdl-layout-title companyName">
              Strategic Claims Direction LLC
            </span>
          </a>

          <div className="mdl-layout-spacer" />

          <nav className="mdl-navigation">
            <a
              className="mdl-navigation__link"
              href="/"
            >
              Home
            </a>
            <a
              className="mdl-navigation__link"
              href="/about"
            >
              About
            </a>
            <a
              className="mdl-navigation__link"
              href="/services"
            >
              Services
            </a>
            <a
              className="mdl-navigation__link"
              href="/claims-audits"
            >
              Claims Audits
            </a>
            <a
              className="mdl-navigation__link"
              href="/publications"
            >
              Publications
            </a>
          </nav>
        </div>
      </header>
      <div className="mdl-layout__drawer">
        <span className="mdl-layout-title">Strategic Claims Direction</span>
        <nav className="mdl-navigation">
          <a
            className="mdl-navigation__link"
            href="/"
          >
            Home
          </a>
          <a
            className="mdl-navigation__link"
            href="/about"
          >
            About
          </a>
          <a
            className="mdl-navigation__link"
            href="/services"
          >
            Services
          </a>
          <a
            className="mdl-navigation__link"
            href="/claims-audits"
          >
            Claims Audits
          </a>
          <a
            className="mdl-navigation__link"
            href="/publications"
          >
            Publications
          </a>
        </nav>
      </div>
    </div>
  )
}

export default Header
